<template>
  <div class="identification-details-container">
    <div class="search">
      <el-date-picker
      v-model="time"
      type="datetimerange"
      :picker-options="pickerOptions"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      align="right">
    </el-date-picker>
    <div class="search-input">
        <el-input
          v-model="UserName"
          clearable
          placeholder="用户名"
        ></el-input>
        <el-input
          v-model="OrganisationName"
          clearable
          placeholder="机构名称"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="onSearch"
          >查询</el-button
        >
      </div>
    </div>
    <el-table :data="tableData" border style="width: 100%" height="574px">
      <el-table-column prop="sourceID" label="ID"> </el-table-column>
      <el-table-column prop="organisationName" label="机构名称">
      </el-table-column>
      <el-table-column prop="userName" label="用户名"> </el-table-column>
      <el-table-column prop="recordDate" label="识别时间"> </el-table-column>
      <el-table-column prop="identifyCount" label="识别张数"> </el-table-column>
      <el-table-column prop="status" :formatter="statusFormat" label="状态">
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="PageIndex"
        :page-sizes="[10]"
        :page-size="PageSize"
        prev-text="上一页"
        next-text="下一页"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
name: "IdentificationDetails";
export default {
  data() {
    return {
      UserName: '',
      OrganisationName: '',
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      time: "",
      PageIndex: 1,
      PageSize: 10,
      total: 0,
      tableData: [],
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    // 搜索
    onSearch() {
      const searchObj = {
        StartTime: this.time[0],
        EndTime: this.time[1],
        OrganisationName: this.OrganisationName,
        UserName: this.UserName
      }
      this.PageIndex = 1
      this.getTableData(searchObj)
    },
    // 页码变化
    handleCurrentChange(index) {
      const searchObj = {
        StartTime: this.time[0],
        EndTime: this.time[1],
        OrganisationName: this.OrganisationName,
        UserName: this.UserName
      }
      this.PageIndex = index;
      this.getTableData(searchObj);
    },
    // 请求数据
    getTableData(searchObj = null) {
      this.$request({
        method: "GET",
        url: "/api/userrole/identification-details",
        params: {
          PageSize: this.PageSize,
          PageIndex: this.PageIndex,
          ...searchObj
        },
      }).then((res) => {
        this.total = res.totalCount;
        this.tableData = res.items;
      });
    },
    // 格式化状态
    statusFormat(value) {
      const map = {
        1: "等待识别",
        2: "识别中",
        3: "识别错误",
        4: "识别完成",
      };
      if (map[value.status]) return map[value.status];
    },
  },
};
</script>

<style lang="less">
.identification-details-container {
  .search {
    margin-bottom: 10px;
    display: flex;

    .search-input {
      margin-left: 10px;
      display: flex;
    }
  }
  position: relative;
  .pagination {
    position: absolute;
    bottom: -35px;
  }
}
</style>